/* eslint-disable no-unused-vars */
export enum ACCESS {
  GENERAL = 'GENERAL',
  // User Module
  CREATE_USER = 'CREATE_USER',
  LIST_USERS = 'LIST_USERS',
  VIEW_USER = 'VIEW_USER',
  UPDATE_USER = 'UPDATE_USER',
  DELETE_USER = 'DELETE_USER',

  // Deleted User
  LIST_DELETED_USER = 'LIST_DELETED_USER',
  VIEW_DELETED_USER = 'VIEW_DELETED_USER',
  RESTORE_USER = 'RESTORE_USER',

  // Role Module
  LIST_ROLES = 'LIST_ROLES',
  LIST_PERMISSION = 'LIST_PERMISSION',
  CREATE_ROLE = 'CREATE_ROLE',
  VIEW_ROLE = 'VIEW_ROLE',
  UPDATE_ROLE = 'UPDATE_ROLE',
  DELETE_ROLE = 'DELETE_ROLE',

  // Media Module
  CREATE_MEDIA = 'CREATE_MEDIA',
  LIST_MEDIAS = 'LIST_MEDIAS',
  VIEW_MEDIA = 'VIEW_MEDIA',
  UPDATE_MEDIA = 'UPDATE_MEDIA',
  DELETE_MEDIA = 'DELETE_MEDIA',

  // Taxonomy module
  CREATE_TAXONOMY = 'CREATE_TAXONOMY',
  LIST_TAXONOMY = 'LIST_TAXONOMY',
  VIEW_TAXONOMY = 'VIEW_TAXONOMY',
  UPDATE_TAXONOMY = 'UPDATE_TAXONOMY',
  DELETE_TAXONOMY = 'DELETE_TAXONOMY',

  // Post module
  CREATE_POST = 'CREATE_POST',
  LIST_POST = 'LIST_POST',
  VIEW_POST = 'VIEW_POST',
  UPDATE_POST = 'UPDATE_POST',
  DELETE_POST = 'DELETE_POST',

  // Package module
  CREATE_PACKAGE = 'CREATE_PACKAGE',
  VIEW_PACKAGE = 'VIEW_PACKAGE',
  UPDATE_PACKAGE = 'UPDATE_PACKAGE',
  DELETE_PACKAGE = 'DELETE_PACKAGE',
  LIST_PACKAGE = 'LIST_PACKAGES',

  // Author module
  CREATE_PRIMARY_AUTHOR = 'CREATE_PRIMARY_AUTHOR',
  VIEW_PRIMARY_AUTHOR = 'VIEW_PRIMARY_AUTHOR',
  UPDATE_PRIMARY_AUTHOR = 'UPDATE_PRIMARY_AUTHOR',
  DELETE_PRIMARY_AUTHOR = 'DELETE_PRIMARY_AUTHOR',
  LIST_PRIMARY_AUTHOR = 'LIST_PRIMARY_AUTHORS',

  // Book module
  CREATE_BOOK = 'CREATE_BOOK',
  VIEW_BOOK = 'VIEW_BOOK',
  UPDATE_BOOK = 'UPDATE_BOOK',
  DELETE_BOOK = 'DELETE_BOOK',
  LIST_BOOKS = 'LIST_BOOKS',

  // Comment module
  CREATE_COMMENT = 'CREATE_COMMENT',
  VIEW_COMMENT = 'VIEW_COMMENT',
  UPDATE_COMMENT = 'UPDATE_COMMENT',
  DELETE_COMMENT = 'DELETE_COMMENT',
  LIST_COMMENT = 'LIST_COMMENT',

  // Rating module
  CREATE_RATING = 'CREATE_RATING',
  VIEW_RATING = 'VIEW_RATING',
  UPDATE_RATING = 'UPDATE_RATING',
  DELETE_RATING = 'DELETE_RATING',
  LIST_RATING = 'LIST_RATING',

  // Transaction
  VIEW_TRANSACTION = 'VIEW_TRANSACTION',
  LIST_TRANSACTION = 'LIST_TRANSACTIONS',
  CREATE_TRANSACTION = 'CREATE_TRANSACTION',
  UPDATE_TRANSACTION = 'UPDATE_TRANSACTION',
  DELETE_TRANSACTION = 'DELETE_TRANSACTION',

  // Postcast module
  CREATE_POSTCAST = 'CREATE_POSTCAST',
  VIEW_POSTCAST = 'VIEW_POSTCAST',
  UPDATE_POSTCAST = 'UPDATE_POSTCAST',
  DELETE_POSTCAST = 'DELETE_POSTCAST',
  LIST_POSTCAST = 'LIST_POSTCAST',

  // Option module
  CREATE_UPDATE_OPTION = 'CREATE_UPDATE_OPTION',
  DELETE_OPTION = 'DELETE_OPTION',
  LIST_OPTION = 'LIST_OPTION',

  // Notification
  SEND_NOTIFICATION_TEMPLATE = 'SEND_NOTIFICATION_TEMPLATE',
  CREATE_NOTIFICATION_TEMPLATE = 'CREATE_NOTIFICATION_TEMPLATE',
  VIEW_NOTIFICATION_TEMPLATE = 'VIEW_NOTIFICATION_TEMPLATE',
  UPDATE_NOTIFICATION_TEMPLATE = 'UPDATE_NOTIFICATION_TEMPLATE',
  DELETE_NOTIFICATION_TEMPLATE = 'DELETE_NOTIFICATION_TEMPLATE',
  LIST_NOTIFICATION_TEMPLATE = 'LIST_NOTIFICATION_TEMPLATE',
  CREATE_NOTIFICATION = 'CREATE_NOTIFICATION',
  DELETE_NOTIFICATION = 'DELETE_NOTIFICATION',

  // Collection module
  CREATE_COLLECTION = 'CREATE_COLLECTION',
  VIEW_COLLECTION = 'VIEW_COLLECTION',
  UPDATE_COLLECTION = 'UPDATE_COLLECTION',
  DELETE_COLLECTION = 'DELETE_COLLECTION',
  LIST_COLLECTIONS = 'LIST_COLLECTIONS',
}

import React, { useEffect, useMemo, useState } from 'react'
import { Menu, MenuProps, Layout, Row, Col, Divider } from 'antd'
import {
  UserOutlined,
  DashboardOutlined,
  SafetyCertificateOutlined,
  FolderAddOutlined,
  ReadOutlined,
  InboxOutlined,
  ShoppingCartOutlined,
  ContainerOutlined,
} from '@ant-design/icons'
import logoApp from '@assets/favicon.png'
import logoTWG from '@assets/logo-twg.svg'
import Breadcrumb from '@components/widgets/Breadcrumb'
import UserDropdownAuth from '@src/components/widgets/UserDropdownAuth'
import { ACCESS } from '@src/configs/permission'
import { usePermissionCurrent } from '@src/hooks'
import { checkAuth } from '@libs/localStorage'
import { Link, useLocation } from 'react-router-dom'

const { Header, Content, Footer, Sider } = Layout

type MenuItem = Required<MenuProps>['items'][number]

const getItem = (
  label: React.ReactNode,
  key: React.Key,
  keyName: ACCESS,
  permissions: ACCESS[],
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem => {
  if (!permissions.includes(keyName)) return null
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem
}

interface ILayoutApp {
  children: JSX.Element
}

function LayoutApp({ children }: ILayoutApp) {
  const location = useLocation()
  const token = checkAuth()
  const { permissions } = usePermissionCurrent(token)

  const [collapsed, setCollapsed] = useState(false)
  const [selectedKeyMenu, setSelectedKeyMenu] = useState<string[]>(location.pathname.split('/').filter((i) => i))

  useEffect(() => {
    const pathSnippets = location.pathname.split('/').filter((i) => i)
    if (pathSnippets && pathSnippets?.length >= 0) setSelectedKeyMenu(pathSnippets)
  }, [])

  const onSelectMenu = ({ keyPath }: { keyPath: string[] }) => {
    setSelectedKeyMenu(keyPath)
  }

  const menuItems: MenuItem[] = useMemo(
    () => [
      getItem(<Link to="/">Dashboard</Link>, 'dashboard', ACCESS.GENERAL, permissions, <DashboardOutlined />),
      getItem('Package', 'packageG', ACCESS.LIST_PACKAGE, permissions, <InboxOutlined />, [
        getItem(<Link to="/package">List package</Link>, 'package', ACCESS.LIST_PACKAGE, permissions),
        getItem(<Link to="/create-package">Create package</Link>, 'create-package', ACCESS.CREATE_PACKAGE, permissions),
      ]),
      getItem('Author', 'authorG', ACCESS.LIST_PACKAGE, permissions, <UserOutlined />, [
        getItem(<Link to="/author">List author</Link>, 'author', ACCESS.LIST_PACKAGE, permissions),
        getItem(<Link to="/create-author">Create author</Link>, 'create-author', ACCESS.CREATE_PACKAGE, permissions),
      ]),
      getItem('Book', 'bookG', ACCESS.LIST_BOOKS, permissions, <ReadOutlined />, [
        getItem(<Link to="/book">List book</Link>, 'book', ACCESS.LIST_BOOKS, permissions),
        getItem(<Link to="/create-book">Create book</Link>, 'create-book', ACCESS.CREATE_BOOK, permissions),
        getItem(<Link to="/book-cat">Category</Link>, 'book-cat', ACCESS.LIST_TAXONOMY, permissions),
      ]),
      getItem('Transaction', 'transactionG', ACCESS.LIST_POST, permissions, <ShoppingCartOutlined />, [
        getItem(
          <Link to="/book-transaction">List book transaction</Link>,
          'book-transaction',
          ACCESS.LIST_POST,
          permissions,
        ),
        getItem(
          <Link to="/package-transaction">List package transaction</Link>,
          'package-transaction',
          ACCESS.CREATE_POST,
          permissions,
        ),
      ]),
      getItem(
        'Notification Template',
        'notificationTemplateG',
        ACCESS.LIST_NOTIFICATION_TEMPLATE,
        permissions,
        <ContainerOutlined />,
        [
          getItem(
            <Link to="/notification-template">List Notification Template</Link>,
            'notification-template',
            ACCESS.LIST_NOTIFICATION_TEMPLATE,
            permissions,
          ),
          getItem(
            <Link to="/create-notification-template">Create Notification Template</Link>,
            'create-notification-template',
            ACCESS.CREATE_NOTIFICATION_TEMPLATE,
            permissions,
          ),
        ],
      ),
      getItem('Collection', 'collectionG', ACCESS.LIST_COLLECTIONS, permissions, <ReadOutlined />, [
        getItem(<Link to="/collection">List collection</Link>, 'collection', ACCESS.LIST_COLLECTIONS, permissions),
        getItem(
          <Link to="/create-collection">Create colllection</Link>,
          'create-collection',
          ACCESS.CREATE_COLLECTION,
          permissions,
        ),
      ]),
      getItem(<Link to="/media">Media</Link>, 'media', ACCESS.LIST_MEDIAS, permissions, <FolderAddOutlined />),
      getItem('User', 'userG', ACCESS.LIST_USERS, permissions, <UserOutlined />, [
        getItem(<Link to="/user">List user</Link>, 'user', ACCESS.LIST_USERS, permissions),
        getItem(<Link to="/create-user">Create user</Link>, 'create-user', ACCESS.CREATE_USER, permissions),
      ]),
      getItem(<Link to="/role">Role</Link>, 'role', ACCESS.LIST_ROLES, permissions, <SafetyCertificateOutlined />),
    ],
    [permissions],
  )

  return (
    <Layout style={{ minHeight: '100vh', padding: 0 }}>
      <Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1200,
          width: '100%',
          paddingLeft: 24,
          paddingRight: 24,
        }}
      >
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <Col style={{ lineHeight: 0 }}>
              <img src={logoApp} alt="logo" width={55} />
            </Col>
            <Col>
              <UserDropdownAuth />
            </Col>
          </Row>
        </Col>
      </Header>
      <Layout style={{ overflow: 'hidden' }}>
        <Sider width={230} collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} theme="light">
          <Menu
            mode="inline"
            style={{ height: '100%', borderRight: 0 }}
            items={menuItems}
            theme="light"
            defaultSelectedKeys={selectedKeyMenu}
            onSelect={onSelectMenu}
          />
        </Sider>
        <Layout style={{ padding: '0 24px 0' }}>
          <Row style={{ margin: '16px 0' }}>
            <Breadcrumb />
          </Row>
          <Divider style={{ margin: 0 }} />
          <Content
            style={{
              paddingTop: 10,
              paddingBottom: 10,
              margin: 0,
              minHeight: 280,
            }}
          >
            <Row>{children}</Row>
          </Content>
          <Footer
            style={{
              paddingTop: 24,
              paddingBottom: 24,
              paddingLeft: 0,
              paddingRight: 0,
              textAlign: 'center',
              display: 'none',
            }}
          >
            {'Designed and Developer by '}
            <a href="https://twinger.vn/" target="_blank" rel="noreferrer">
              <img src={logoTWG} alt="logo" width={90} />
            </a>
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default LayoutApp
